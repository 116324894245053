import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Section4 = () => (
  <div className="meditation-section-4">
    <FormattedHTMLMessage
      tagName="h1"
      id="meditation.mostWhoTry"
      defaultMessage="Most who try a session find it to be an invaluable part of their week. <b>Join us!</b>"
    />
  </div>
);

Section4.propTypes = {};

export default Section4;
