/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import step1 from '../../../images/meditation/step1.png';
import step2 from '../../../images/meditation/step2.png';
import step3 from '../../../images/meditation/step3.png';


const ReferralSteps = ({ intl }) => {
  const steps = [
    {
      text: (
        <p>{intl.formatMessage({ id: 'meditation.firstStep', defaultMessage: 'Join the video session (done by Zoom video conferencing- everyone is welcome to join!)' })}</p>
      ),
      image: step1,
    },
    {
      text: (
        <FormattedHTMLMessage tagName="p" id="meditation.secondStep" defaultMessage="A mental health professional guides the group using a unique blend of meditation and mindfulness techniques. <b>It is effortless, and most find it a deeply relaxing experience.</b>" />
      ),
      image: step2,
    },
    {
      text: (
        <p>{intl.formatMessage({ id: 'meditation.thirdStep', defaultMessage: 'After twenty minutes, those who are interested can participate in a discussion of their experience.' })}</p>
      ),
      image: step3,
    },
  ];
  return (
    <div className="steps">
      {
        steps.map((step, index) => {
          return (
            <React.Fragment key={`key-${index}`}>
              <div className="step-item" key={`key-${index}`}>
                <div
                  className="si-checkbox"
                  style={{ backgroundImage: `url(${step.image})` }}
                />
                <div className="si-text">
                  {step.text}
                </div>
              </div>
              {steps.length - 1 !== index && (
              <div className="step-vertical-line">
                <div className="svl-1" />
              </div>
                )}
            </React.Fragment>
            );
        })
      }
    </div>
  );
};

ReferralSteps.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(ReferralSteps);
