import React from 'react';
import { FormattedMessage } from 'react-intl';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';
import section11Animation from '../../images/new/animations/section-11-animation.png';

const Section5 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 1,
    });
  };
  return (
    <div className="section-11">
      <div className="flex-row">
        <div className="half-column">
          <div className="hero">
            <h1 style={{ fontSize: '34px' }}>
              <FormattedMessage
                id="meditation.limitedTime"
                defaultMessage="For a limited time, these sessions are completely FREE."
              />
            </h1>
            <button
              onClick={toggleSignUp}
              style={{
                border: 'solid 2px #2a5ccd',
                backgroundColor: '#ffffff',
                color: '#2a5ccd',
              }}
            >
              <FormattedMessage
                id="new.freeMeditation"
                defaultMessage="Try Out Meditation for Free!"
              />
            </button>
          </div>
        </div>
        <div className="half-column">
          <img src={section11Animation} alt="Canadian video counselling" />
        </div>
      </div>
    </div>
  );
};

Section5.propTypes = {};

export default Section5;
