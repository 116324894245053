import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import ReferralSteps from './partials/steps';
import section3animation from '../../images/meditation/section-3-animation.svg';

const Section3 = () => (
  <div className="meditation-section-3">
    <FormattedHTMLMessage
      tagName="p"
      id="meditation.guidedMeditation"
      defaultMessage="Guided meditation in the comfort of your own space - no prior knowledge required."
    />
    <div className="steps-section">
      <img src={section3animation} alt="What happens during Inkblot Meditation" id="animation" />
      <div className="steps-text">
        <FormattedHTMLMessage
          tagName="h4"
          id="meditation.whatHappens"
          defaultMessage="What happens during an Inkblot Meditation Session:"
        />
        <ReferralSteps currentStep={3} />
      </div>
    </div>
  </div>
);

Section3.propTypes = {};

export default Section3;
