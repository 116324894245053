import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { unauthAuditProgress } from '../../utils/pageUtils';
import { logEvent, OPEN_SIGNUP } from '../../utils/amplitude';
import section1Animation from '../../images/meditation/section-1-animation.png';

const Section1 = () => {
  const toggleSignUp = () => {
    localStorage.setItem('signUp', 'open');
    logEvent(OPEN_SIGNUP, { page: '' });
    unauthAuditProgress('', 'open_signup');
    window.scrollTo({
      top: window.pageYOffset + 1,
    });
  };
  return (
    <div className="section-1">
      <div className="flex-row">
        <div className="half-column">
          <div className="hero">
            <FormattedHTMLMessage
              tagName="h1"
              id="meditation.feelBetter"
              defaultMessage="<i>Feel better</i> by trying out an Inkblot Meditation session."
            />
            <FormattedHTMLMessage
              tagName="p"
              id="meditation.highlyRated"
              defaultMessage="These highly rated sessions are <b>30 minutes, fully-guided</b> and conveniently done by <b>video!</b>"
            />
            <button onClick={toggleSignUp}>
              <FormattedMessage
                id="new.freeMeditation"
                defaultMessage="Try Out Meditation for Free!"
              />
            </button>
          </div>
        </div>
        <div className="half-column">
          <img src={section1Animation} alt="Canadian video counselling" />
        </div>
      </div>
    </div>
  );
};

Section1.propTypes = {};

export default Section1;
